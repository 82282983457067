import React from 'react'

const Map = () => (
  <section className="map">
    <div className="map-holder">
      <iframe
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.1611282064623!2d-120.27636618388159!3d50.67983407950768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537e2d8e9a7d2d4f%3A0x8b19cf964502cd36!2sKnit2+Yarns!5e0!3m2!1sen!2sca!4v1460099683436"
      />
    </div>
  </section>
)

export default Map
